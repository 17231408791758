import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EmailHelpers from 'components/data/EmailHelpers';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import { VisualStackEditorToolbox } from 'components/ui-base/VisualStackEditor';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import EmailEditorContentBlock from './content-block';
import EmailEditorMobilePreview from './mobile-preview';
import '../styles/content-area.scss';

/**
 * EmailEditorContentArea
 * The Email Editor Content Area is the area where you add multiple email blocks
 */
class EmailEditorContentArea extends Component {
    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, html: PropTypes.string, canMove: PropTypes.bool, canRemove: PropTypes.bool })),
        onAddBlockClickedForIndex: PropTypes.func
    };

    static defaultProps = {
        value: [],
        onAddBlockClickedForIndex: () => {}
    };

    constructor(props) {
        super(props);

        const { dataModel, settingsModel, value } = props;
        this.state = {
            editor: EmailHelpers.outputEmail(dataModel, settingsModel, value)
        };
    }

    /**
     * set de testgroup array of groups shown in the editor
     * @param {string} t testgroup to hide or show
     */
    selectTestGroup = (t) => {
        const { abTestGroupsShown, dataModel, settingsModel, value } = this.props;

        if (abTestGroupsShown.includes(t)) {
            abTestGroupsShown.splice(abTestGroupsShown.indexOf(t), 1);
        } else {
            abTestGroupsShown.push(t);
        }
        this.setState({
            abTestGroupsShown,
            editor: EmailHelpers.outputEmail(dataModel, settingsModel, value)
        });
    };

    /**
     * Check whether we need to rerender the email
     * @param {object} prevProps
     * @param {object} prevState
     */
    componentDidUpdate(prevProps) {
        const { sourceData, dataModel, settingsModel, value, activeBlock, onUpdatePermissions, onMoveToolbox, onSortBlocks } = this.props;

        // Rebuild email HTML on order change
        if (prevProps.value !== value) {
            this.setState(
                {
                    editor: EmailHelpers.outputEmail(dataModel, settingsModel, value)
                },
                () => onMoveToolbox()
            );
        }

        // Rebuild email HTML on data change and update permissions for permissions set in content.
        if (prevProps.sourceData !== sourceData) {
            this.setState(
                {
                    editor: EmailHelpers.outputEmail(dataModel, settingsModel, value)
                },
                () => onUpdatePermissions()
            );
        }

        // Check whether a block should be moved to the top.
        if (
            prevProps.sourceData[activeBlock] &&
            (prevProps.sourceData[activeBlock].pinnedToTop !== sourceData[activeBlock].pinnedToTop ||
                prevProps.sourceData[activeBlock].pinnedToBottom !== sourceData[activeBlock].pinnedToBottom)
        ) {
            if (sourceData[activeBlock].pinnedToTop || sourceData[activeBlock].pinnedToBottom) {
                onSortBlocks();
            }
        }
    }

    /**
     * Renders the components
     * @returns {*}
     */
    render() {
        const {
            sourceBlockUuid,
            onOpenAddBlockDialog,
            onDelete,
            onCopy,
            onHoverBlock,
            onStartDragBlock,
            onEndDragBlock,
            onSetActive,
            onMoveToolbox,
            onMoveBlockKeys,
            activeBlock,
            canEdit,
            canMove,
            canRemove,
            canAdd,
            abTesting,
            abTestGroupsShown,
            mobilePreview,
            emailBase
        } = this.props;
        const { editor } = this.state;

        return (
            <div
                className={classNames('email-editor-content-area', {
                    'email-editor-content-area--mobile': mobilePreview
                })}
                id="email-editor-content-area">
                {activeBlock && (
                    <VisualStackEditorToolbox
                        className="email-editor-content-area__toolbox"
                        onDelete={onDelete}
                        onCopy={onCopy}
                        onMoveBlockKeys={onMoveBlockKeys}
                        onOpenAddBlockDialog={onOpenAddBlockDialog}
                        activeBlock={activeBlock}
                        activeFirst={editor.length > 0 && editor[0].uuid === activeBlock}
                        activeLast={editor.length > 0 && editor[editor.length - 1].uuid === activeBlock}
                        canEdit={canEdit}
                        canMove={canMove}
                        canRemove={canRemove}
                        canAdd={canAdd}
                    />
                )}
                {editor.length > 0 &&
                    !mobilePreview &&
                    editor.map((item) => {
                        const hidden = VisualStackEditorHelpers.getHidden(item?.abTestGroups, abTesting, abTestGroupsShown);
                        return hidden ? null : (
                            <EmailEditorContentBlock
                                key={item.uuid}
                                uuid={item.uuid}
                                html={item.html}
                                canMove={canMove}
                                pinnedToTop={item.pinnedToTop}
                                pinnedToBottom={item.pinnedToBottom}
                                workingTitle={item.workingTitle}
                                abTesting={abTesting}
                                abTestGroups={item.abTestGroups}
                                activeBlock={activeBlock}
                                sourceBlockUuid={sourceBlockUuid}
                                onHoverBlock={onHoverBlock}
                                onStartDragBlock={onStartDragBlock}
                                onEndDragBlock={onEndDragBlock}
                                onSetActive={onSetActive}
                                onMoveToolbox={onMoveToolbox}
                            />
                        );
                    })}
                {editor.length > 0 && mobilePreview && (
                    <EmailEditorMobilePreview editor={editor} emailBase={emailBase} abTesting={abTesting} abTestGroupsShown={abTestGroupsShown} />
                )}
                {editor.length === 0 && (
                    <EmptyState
                        className="email-editor-content-area__empty-state"
                        illustration={<Illustration illustration="fallback" />}
                        primaryText="You haven't created an email yet!"
                        secondaryText="Ready to build?"
                        primaryButton={{ label: 'Add a block', onClick: () => onOpenAddBlockDialog() }}
                    />
                )}
            </div>
        );
    }
}

export default EmailEditorContentArea;
