import React from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import EmailHelpers from 'components/data/EmailHelpers';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import { withTemplates } from 'components/data/Templates';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import { EmailEditorMobilePreview } from '../../EmailEditor';
import '../styles/render-email.scss';

class RenderEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailBase: EmailHelpers.getEmailBase(props.groupKey)
        };
    }

    render() {
        const { emailBase } = this.state;
        const { canEdit, onOpenEditor, abTesting, abTestGroupsShown, dataModel, settingsModel, value, mobilePreview } = this.props;
        const items = EmailHelpers.outputEmail(dataModel, settingsModel, value);

        return (
            <React.Fragment>
                {items.length === 0 ? (
                    <EmptyState
                        className="email-preview-render-email__empty-state"
                        illustration={<Illustration illustration="fallback" />}
                        primaryText="You haven't created an email yet!"
                        secondaryText="Ready to build?"
                        primaryButton={canEdit && { label: 'Start creating', onClick: () => onOpenEditor() }}
                    />
                ) : (
                    <div
                        className={classNames('email-preview-render-email', {
                            'email-preview-render-email--mobile': mobilePreview
                        })}>
                        {mobilePreview && (
                            <EmailEditorMobilePreview
                                editor={items}
                                emailBase={emailBase}
                                abTesting={abTesting}
                                abTestGroupsShown={abTestGroupsShown}
                                settingsModel={settingsModel}
                            />
                        )}
                        {!mobilePreview &&
                            items.map((item) => {
                                const hidden = VisualStackEditorHelpers.getHidden(item?.abTestGroups, abTesting, abTestGroupsShown);
                                return hidden ? null : (
                                    <div key={item.uuid} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.html, { ADD_ATTR: ['target'] }) }}></div>
                                );
                            })}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default withTemplates(RenderEmail, [
    { type: 'emailBlock', fullData: true },
    { type: 'emailBase', fullData: true }
]);
